import useWindowSize from '@/hooks/useWindowSize';
import { IDropOff, IPickUp } from '@/types/interface';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  type: '' | 'pickUp' | 'dropOff';
  onClose: () => void;
  setMapValue: (value: string) => void;
  pickUpData: IPickUp | null;
  setPickUpData: (data: IPickUp | null) => void;
  dropOffData?: IDropOff | null;
  setDropOffData?: (data: IDropOff | null) => void;
}

const mapService: any = { current: null };
const geocoderService = { current: null };
const infoWindow: any = { current: null };
const defaultCenter = { lat: 15.8774773, lng: 108.3284642 };

function MapDialog(props: IProps) {
  const {
    type,
    onClose,
    setMapValue,
    pickUpData,
    setPickUpData,
    dropOffData,
    setDropOffData,
  } = props;
  const { t } = useTranslation('common');
  const map = useRef(null);
  const [place, setPlace] = useState<(IPickUp & IDropOff) | null>(null);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const { width } = useWindowSize();
  const isNotComputer = useMemo(() => width < 800, [width]);

  const limitedCoordinates = [
    { lat: 15.9209039, lng: 108.3248058 },
    { lat: 15.9106931, lng: 108.2997749 },
    { lat: 15.8997146, lng: 108.2938526 },
    { lat: 15.8881577, lng: 108.2885311 },
    { lat: 15.8763413, lng: 108.2991505 },
    { lat: 15.8745862, lng: 108.3173569 },
    { lat: 15.8663303, lng: 108.3337506 },
    { lat: 15.8684768, lng: 108.3457669 },
    { lat: 15.8650093, lng: 108.3499726 },
    { lat: 15.863647, lng: 108.3590062 },
    { lat: 15.8677338, lng: 108.3611306 },
    { lat: 15.8700455, lng: 108.3738335 },
    { lat: 15.8732247, lng: 108.3916092 },
    { lat: 15.8759896, lng: 108.3936604 },
    { lat: 15.8830068, lng: 108.390313 },
    { lat: 15.9236914, lng: 108.3272811 },
    { lat: 15.9209039, lng: 108.3248058 },
  ];
  let limitedPath = new (window as any).google.maps.Polyline({
    path: limitedCoordinates,
    geodesic: true,
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  });

  const handleFocusTips = useCallback(() => {
    setOpenTooltip(true);
  }, []);

  const handleBlurTips = useCallback(() => {
    setOpenTooltip(false);
  }, []);

  const handleClose = useCallback(() => {
    if (type === 'pickUp') {
      setMapValue(pickUpData?.pickUpAddress ?? '');
    }

    if (type === 'dropOff') {
      setMapValue(dropOffData?.dropOffAddress ?? '');
    }

    onClose();
  }, [dropOffData?.dropOffAddress, pickUpData?.pickUpAddress, type]);

  const handleConfirm = useCallback(() => {
    if (type === 'pickUp' && !!place) {
      setPickUpData({ ...place });
      setMapValue(place?.pickUpAddress ?? '');
    }

    if (type === 'dropOff' && !!place) {
      setDropOffData?.({ ...place });
      setMapValue(place?.dropOffAddress ?? '');
    }

    onClose();
  }, [place, type]);

  const fetchDetail = useCallback(
    (request: any, marker: any) => {
      if (!geocoderService.current && (window as any).google) {
        geocoderService.current = new (window as any).google.maps.Geocoder();
      }

      if (!geocoderService.current) {
        return undefined;
      }

      (geocoderService.current as any).geocode(request, (results?: any) => {
        if (infoWindow.current) infoWindow.current.close();

        const location = results[0].geometry.location;
        const latLng = new (window as any).google.maps.LatLng(
          location.lat(),
          location.lng(),
        );

        if (
          (window as any).google.maps.geometry.poly.containsLocation(
            latLng,
            limitedPath,
          )
        ) {
          setPlace({
            [type]: JSON.stringify(location.toJSON()),
            [`${type}Address`]: results[0].formatted_address,
            [`${type}Ward`]: '',
            [`${type}District`]: '',
            [`${type}City`]: '',
          });

          infoWindow.current = new (window as any).google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          infoWindow.current.open({
            anchor: marker,
            map,
          });
        } else {
          setPlace(null);
        }
      });
    },
    [type],
  );
  const handleMarker = useCallback(
    (position: { lat: number; lng: number }, url: string) => {
      const myMarker = new (window as any).google.maps.Marker({
        position,
        map: mapService.current,
        draggable: true,
        animation: (window as any).google.maps.Animation.DROP,
        icon: {
          url,
          anchor: new (window as any).google.maps.Point(28, 32),
          scaledSize: new (window as any).google.maps.Size(65, 65),
        },
      });

      mapService.current.setCenter(position);

      new (window as any).google.maps.event.addListener(
        myMarker,
        'dragend',
        function (marker: any) {
          fetchDetail(
            {
              location: {
                lat: marker.latLng.lat(),
                lng: marker.latLng.lng(),
              },
            },
            myMarker,
          );
        },
      );

      new (window as any).google.maps.event.addListener(
        mapService.current,
        'drag',
        function () {
          myMarker.setPosition(mapService.current.getCenter());
        },
      );

      new (window as any).google.maps.event.addListener(
        mapService.current,
        'dragend',
        function () {
          fetchDetail(
            {
              location: {
                lat: mapService.current.getCenter().lat(),
                lng: mapService.current.getCenter().lng(),
              },
            },
            myMarker,
          );
        },
      );
    },
    [type],
  );

  useEffect(() => {
    if (!type) {
      map.current = null;
      mapService.current = null;
      setPlace(null);
    }

    if (!(window as any).google || !map.current) return;

    if (!mapService.current) {
      mapService.current = new (window as any).google.maps.Map(map.current, {
        center: defaultCenter,
        zoom: 15,
        mapTypeControl: false,
        panControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
      });

      limitedPath.setMap(mapService.current);
    }

    if (!mapService.current) return;

    if (type === 'pickUp') {
      const position = pickUpData?.pickUp
        ? JSON.parse(pickUpData.pickUp)
        : defaultCenter;

      handleMarker(position, '/assets/images/start.png');
    }

    if (type === 'dropOff') {
      const position = dropOffData?.dropOff
        ? JSON.parse(dropOffData.dropOff)
        : defaultCenter;

      handleMarker(position, '/assets/images/finish.png');
    }
  }, [handleMarker, type]);

  return (
    <Dialog
      disablePortal
      fullScreen={isNotComputer}
      fullWidth={true}
      maxWidth="lg"
      open={type !== ''}
      onClose={handleClose}
    >
      <DialogContent
        ref={map}
        sx={{
          height: '500px',
          m: '10px 10px 0px 10px',
        }}
      />
      <DialogActions
        sx={{
          p: '0px 24px',
          m: '20px 0px',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          variant="outlined"
          size={isNotComputer ? 'medium' : 'large'}
          onClick={handleClose}
        >
          {t('close')}
        </Button>
        <Button
          variant="contained"
          size={isNotComputer ? 'medium' : 'large'}
          onClick={handleConfirm}
          disabled={!place}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
      <Tooltip
        arrow
        title={t('guideMap')}
        placement="left"
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          color: '#0870D9',
          cursor: 'pointer',
        }}
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: '12px',
              margin: '0px 10px 0px 0px !important',
              padding: '14px',
              width: '500px',
              fontSize: '14px',
              lineHeight: '16px',
            },
          },
        }}
      >
        <InfoIcon
          fontSize="large"
          onFocus={handleFocusTips}
          onBlur={handleBlurTips}
        />
      </Tooltip>
    </Dialog>
  );
}

export default MapDialog;
