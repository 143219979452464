/* eslint-disable react/no-unescaped-entities */
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from './style';
import ConfirmModal from '../ConfirmModal';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  paymentUrl: string;
  yesAction: () => void;
};

export default function MBBankPaymentModal({
  isOpen,
  paymentUrl,
  yesAction,
}: Props) {
  const { t } = useTranslation('common');
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState<boolean>(false);

  const showConfirmModal = () => {
    setShowConfirmCloseModal(true)
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={showConfirmModal}
      >

        <Container
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <iframe src={paymentUrl} width="100%" height="100%"></iframe>
        </Container>


      </Modal>

      <ConfirmModal
        isOpen={showConfirmCloseModal}
        title={t('common:confirmMove')}
        description={""}
        yesAction={() => {
          setShowConfirmCloseModal(false);
          yesAction();
        }}
        noAction={() => setShowConfirmCloseModal(false)}
      />
    </>
  );
}
