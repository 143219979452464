import { IPickUp } from '@/types/interface';
import { Tour, TourType } from '@/types/tourBooking';
import {
  convertMinutesToDaysMinutes,
  formatMillisecondsToHours,
} from '@/utils/formatTime';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const formatDate = 'DD/MM/YYYY';
const formatTime = 'HH:mm';

// const validateBookingTime = (
//   time: Moment,
//   startTimeValue?: string,
//   endTimeValue?: string,
// ) => {
//   const bookingTime = moment(time, formatTime);
//   const startTime = moment(startTimeValue ?? '07:00', formatTime);
//   const endTime = moment(endTimeValue ?? '23:00', formatTime);

//   return bookingTime.isBetween(startTime, endTime, null, '[)');
// };

function validateReserveTime(
  futureDays: number,
  futureMinutes: number,
  time: Moment,
  date: Moment,
  tourType: TourType,
): boolean {
  const bookingTime = moment(time, formatTime);
  const bookingDateTime = moment(date, formatDate).set({
    hour: bookingTime.get('hour'),
    minute: bookingTime.get('minute'),
  });

  const currentTime = moment();
  const futureTime = moment()
    .add(futureDays, 'days')
    .add(futureMinutes, 'minutes');

  const isBookingFromFutureDay = bookingDateTime.isAfter(currentTime);

  // TODO: tour type: experience
  if (tourType && tourType === TourType.EXPERIENCE) {
    return isBookingFromFutureDay;
  }

  // TODO: tour type: adventures -> Book from future day
  else if (isBookingFromFutureDay) {
    return true;
  }

  // TODO: tour type: adventures -> Book in same day

  const currentTimeOnly = moment(moment(), formatTime);

  return bookingTime.isAfter(currentTimeOnly);
}

interface IUseTourBookingProps {
  service: any;
  tourType?: TourType;
  currentTour: Tour | null;
}

export default function useTourBooking({
  service,
  tourType,
  currentTour,
}: IUseTourBookingProps) {
  const { t } = useTranslation(['validate', 'error']);

  const [pickUpData, setPickUpData] = useState<IPickUp | null>(null);
  const [isChangedTimer, setIsChangedTimer] = useState<boolean>(false);
  const [date, setDate] = useState<Moment>(moment());
  const [dateErrMsg, setDateErrMsg] = useState<string>('');
  const [time, setTime] = useState<Moment>(moment().add(30, 'minutes'));
  const [timeErrMsg, setTimeErrMsg] = useState<string>('');
  const [successBooking, setSuccessBooking] = useState<boolean>(false);
  const [bookingErrMsg, setBookingErrMsg] = useState('');
  const [bookingDone, setBookingDone] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [minDate, setMinDate] = useState<Moment>(moment());
  const [timeOptions, setTimeOptions] = useState<{ label: string; value: number }[]>([]);
  const [timeIdSelected, setTimeIdSelected] = useState<number>();
  const [timeAvailable, setTimeAvailable] = useState<any>();
  const [isAdventureTour, setIsAdventureTour] = useState<boolean>(false);

  const timeForAdventureTour = [
    { id: 730, hour: 7, minute: 30, format: '7:30' },
    { id: 800, hour: 8, minute: 0, format: '8:00' },
    { id: 830, hour: 8, minute: 30, format: '8:30' },
    { id: 900, hour: 9, minute: 0, format: '9:00' },
    { id: 930, hour: 9, minute: 30, format: '9:30' },
    { id: 1000, hour: 10, minute: 0, format: '10:00' },
    { id: 1030, hour: 10, minute: 30, format: '10:30' },
    { id: 1100, hour: 11, minute: 0, format: '11:00' },
    { id: 1130, hour: 11, minute: 30, format: '11:30' },
    { id: 1200, hour: 12, minute: 0, format: '12:00' },
    { id: 1230, hour: 12, minute: 30, format: '12:30' },
    { id: 1300, hour: 13, minute: 0, format: '13:00' },
    { id: 1330, hour: 13, minute: 30, format: '13:30' },
    { id: 1400, hour: 14, minute: 0, format: '14:00' },
    { id: 1430, hour: 14, minute: 30, format: '14:30' },
    { id: 1500, hour: 15, minute: 0, format: '15:00' },
    { id: 1530, hour: 15, minute: 30, format: '15:30' },
    { id: 1600, hour: 16, minute: 0, format: '16:00' },
    { id: 1630, hour: 16, minute: 30, format: '16:30' },
    { id: 1700, hour: 17, minute: 0, format: '17:00' },
    { id: 1730, hour: 17, minute: 30, format: '17:30' },
    { id: 1800, hour: 18, minute: 0, format: '18:00' },
    { id: 1830, hour: 18, minute: 30, format: '18:30' },
    { id: 1900, hour: 19, minute: 0, format: '19:00' },
    { id: 1930, hour: 19, minute: 30, format: '19:30' },
    { id: 2000, hour: 20, minute: 0, format: '20:00' },
    { id: 2030, hour: 20, minute: 30, format: '20:30' },
    { id: 2100, hour: 21, minute: 0, format: '21:00' },
  ];

  const timeForExperienceTour = [
    { id: 730, hour: 7, minute: 30, format: '7:30' },
    { id: 800, hour: 8, minute: 0, format: '8:00' },
    { id: 900, hour: 9, minute: 0, format: '9:00' },
    { id: 1000, hour: 10, minute: 0, format: '10:00' },
    { id: 1400, hour: 14, minute: 0, format: '14:00' },
    { id: 1500, hour: 15, minute: 0, format: '15:00' },
    { id: 1600, hour: 16, minute: 0, format: '16:00' },
  ];

  const contactNumberTypeOptions = [
    { label: t('common:phone'), value: 'phone' },
    { label: 'Zalo', value: 'zalo' },
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'Kakaotalk', value: 'kakaotalk' },
    { label: 'Viber', value: 'viber' },
  ];

  // check date is today or future
  const isCurrentDate = useMemo(() => {
    if (!isChangedTimer) return false;

    if (date) {
      return date.format(formatDate) === moment().format(formatDate);
    } else return false;
  }, [date, isChangedTimer]);

  useEffect(() => {
    if (!tourType) { return; }
    if (tourType === TourType.ADVENTURES) {
      setIsAdventureTour(true);
      initDateByAdventure();
    }
    else {
      setIsAdventureTour(false);
      initDateByExperience();
    }
  }, [tourType]);

  function initDateByExperience() {
    let currentDate = moment();
    currentDate = currentDate.add(2, 'days');

    const firstTime = timeForExperienceTour[0];

    setTimeIdSelected(firstTime.id);
    const timeOptions = timeForExperienceTour.map((time: any) => ({
      label: time.format,
      value: time.id
    }));

    currentDate.set('hour', firstTime.hour);
    currentDate.set('minute', firstTime.minute);

    setTimeOptions(timeOptions);
    setTimeAvailable(timeForExperienceTour);

    setDate(currentDate);
    setTime(currentDate);
    setMinDate(currentDate);
  }

  function initDateByAdventure() {
    let currentDate = moment();
    currentDate.add(15, 'minute');

    const currentHour = currentDate.get('hour');
    const currentMinute = currentDate.get('minute');

    let findTimeAvailable = timeForAdventureTour
      .filter((time: any) => time.hour > currentHour ||
        (time.hour == currentHour && time.minute >= currentMinute)
      );

    if (findTimeAvailable.length === 0) {
      findTimeAvailable = timeForAdventureTour;
      currentDate.add('1', 'day');
    }

    const firstTime = findTimeAvailable[0];
    setTimeIdSelected(firstTime.id);

    currentDate.set('hour', firstTime.hour);
    currentDate.set('minute', firstTime.minute);

    const timeOptions = findTimeAvailable.map((time: any) => ({
      label: time.format,
      value: time.id
    }));

    setTimeAvailable(findTimeAvailable);
    setTimeOptions(timeOptions);

    setDate(currentDate);
    setTime(currentDate);
    setMinDate(currentDate);
  }

  function isValidScheduleDate() {
    if (!isAdventureTour) {
      setDateTimeErrMsg(null, 'time');
      return true;
    }

    const currentDate = moment();

    let findTimeAvailable = timeAvailable;

    if (date.isSame(currentDate, 'day')) {
      let hour = currentDate.get('hour');
      let minute = currentDate.get('minute');

      findTimeAvailable = timeAvailable
        .filter((time: any) => time.hour > hour ||
          (time.hour == hour && time.minute >= minute)
        );
    }

    const findTimeIndex = findTimeAvailable.findIndex(
      (time: any) => time.id === timeIdSelected
    );

    if (findTimeIndex > -1) {
      setDateTimeErrMsg(null, 'time');
      return true;
    }

    setDateTimeErrMsg('disablePast', 'time');
    return false;
  }

  // set error message for time, date inputs
  const setDateTimeErrMsg = useCallback(
    (validationError: string | null, type: 'date' | 'time') => {
      let text = '';
      if (validationError === 'invalidDate') {
        text = `${type}Invalid`;
      } else if (validationError === 'disablePast') {
        text = `${type}IsOver`;
      }

      type === 'date' ? setDateErrMsg(text) : setTimeErrMsg(text);
    },
    [],
  );

  const doSubmit = useCallback(async (body: any) => {
    setLoading(true);
    setBookingErrMsg('');
    setSuccessBooking(false);

    try {
      const { data } = await service.createBooking(body);
      if (data) {
        setBookingDone(data);
      }

      setSuccessBooking(true);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setBookingErrMsg(error?.response?.data?.message);
      } else setBookingErrMsg(`${t('common:failBooking')}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const doSubmitByPartner = useCallback(async (body: any) => {
    setLoading(true);
    setBookingErrMsg('');
    setSuccessBooking(false);

    try {
      const { data } = await service.createBookingByPartner(body);
      if (data) {
        setBookingDone(data);
      }

      setSuccessBooking(true);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setBookingErrMsg(error?.response?.data?.message);
      } else setBookingErrMsg(`${t('common:failBooking')}`);
    } finally {
      setLoading(false);
    }
  }, []);

  // check pickUp
  const checkPickUpAndDropOff = useCallback(
    (setValue: any, clearErrors: any) => {
      // if (!pickUpData) {
      //   setDistanceInformation({ km: 0, price: 0 });
      // }

      if (!!pickUpData) {
        clearErrors(['pickUp']);
      }
    },
    [pickUpData],
  );

  // validate date and time
  // useEffect(() => {
  //   if (isCurrentDate && moment(time).isBefore(moment())) {
  //     setDateTimeErrMsg('disablePast', 'time');
  //   } else {
  //     setDateTimeErrMsg(null, 'time');
  //   }
  // }, [isCurrentDate, time]);

  useEffect(() => {
    var msg = t('validate:timeInvalid');

    if (!!currentTour) {
      // Validate selected time after current time reverve time config in the tour.
      if (currentTour.reserveTime) {
        const { days, minutes } = convertMinutesToDaysMinutes(
          currentTour.reserveTime,
        );

        if (
          tourType &&
          !validateReserveTime(days, minutes, time, date, tourType)
        ) {
          var durationValue = '';
          var timeValue = '';

          if (days > 0) {
            durationValue = `${days}`;
            if (days > 1) {
              timeValue = ` ${t('common:days')} `;
            } else {
              timeValue = ` ${t('common:day')} `;
            }
          }

          if (minutes > 0) {
            if (durationValue !== '') {
              durationValue += `:${minutes}`;
            } else {
              durationValue = `${minutes}`;
            }

            if (timeValue === '') {
              if (minutes === 1) {
                timeValue = ` ${t('common:min')} `;
              } else {
                timeValue = ` ${t('common:mins')} `;
              }
            }
          }

          if (durationValue && timeValue) {
            const msg = t('validate:tourDate', {
              durationValue: durationValue,
              timeValue: timeValue,
            });
            setDateTimeErrMsg(msg, 'date');
            setTimeErrMsg(msg);
            return;
          }
        }
      }

      // Validate selected time is between start - end time if current tour have values start - end time
      // if (currentTour.startActiveTime && currentTour.endActiveTime) {
      //   const fromTime = formatMillisecondsToHours(currentTour.startActiveTime);
      //   const toTime = formatMillisecondsToHours(currentTour.endActiveTime);

      //   if (!validateBookingTime(time, fromTime, toTime)) {
      //     msg = t('validate:tourTime', {
      //       fromTime: fromTime,
      //       toTime: toTime,
      //     });
      //     setDateTimeErrMsg('', 'date');
      //     setTimeErrMsg(msg);
      //     return;
      //   }
      // }
    }
  }, [time, date]);

  useEffect(() => {

    if (timeIdSelected) {
      if (!isValidScheduleDate()) {
        return;
      }

      const findTime = timeAvailable.find((t: any) => t.id === timeIdSelected);

      if (findTime) {
        date.set('hour', findTime.hour);
        date.set('minute', findTime.minute);

        setTime(date);
      }
    }
  }, [timeIdSelected]);

  function onChangeDate(value: Date) {
    if (!isAdventureTour) {
      return;
    }

    const selectedDate = moment(value);
    const now = moment();
    let findTimeAvailable = timeForAdventureTour;

    if (selectedDate.isSame(now, 'day')) {
      let hour = now.get('hour');
      let minute = now.get('minute');

      findTimeAvailable = findTimeAvailable
        .filter((time: any) => time.hour > hour ||
          (time.hour == hour && time.minute >= minute)
        );
    }

    const timeOptions = findTimeAvailable.map((time: any) => ({
      label: time.format,
      value: time.id
    }));

    setTimeAvailable(findTimeAvailable);
    setTimeOptions(timeOptions);
  };

  return {
    pickUpData,
    setPickUpData,
    isChangedTimer,
    setIsChangedTimer,
    date,
    setDate,
    onChangeDate,
    dateErrMsg,
    time,
    setTime,
    timeErrMsg,
    successBooking,
    setSuccessBooking,
    bookingErrMsg,
    bookingDone,
    loading,
    isCurrentDate,
    setDateTimeErrMsg,
    doSubmit,
    doSubmitByPartner,
    checkPickUpAndDropOff,
    minDate,
    timeOptions,
    setTimeIdSelected,
    contactNumberTypeOptions,
    isValidScheduleDate
  };
}
