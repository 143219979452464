/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
import ImageModal from '@/components/ImageModal';
import PreLoading from '@/components/PreLoading';
import { convertTourDetailImages } from '@/containers/TourBookingByCustomer/providers/TourBookingProvider';
import { useTourBookingContext } from '@/containers/TourBookingByCustomer/providers/useTourBookingContext';
import { TourDetail } from '@/types/tourBooking';
import { formatPrice } from '@/utils/formatPrice';
import { Button, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TermsModal from '../TermsModal';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  TermsLink,
  Wrapper,
} from './style';

type Props = {
  onSelect: () => void;
};

const itemData = [
  {
    rows: 2,
    cols: 2,
  },
  {},
  {},
  {},
  {},
];

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function TourBookingDetailModal({ onSelect }: Props) {
  const { t } = useTranslation('common');
  const {
    selectedTourIdForDetail,
    setSelectedTourIdForDetail,
    setSelectedTourIdForBooking,
    locale,
  } = useTourBookingContext();
  const [tourDetail, setTourDetail] = useState<TourDetail | null>(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  useEffect(() => {
    const fetchTourDetail = async () => {
      if (selectedTourIdForDetail && locale) {
        const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

        try {
          const res = await fetch(
            `${API_URL}booking_tour/${locale}/tour/${selectedTourIdForDetail}`,
          );
          if (!res.ok) {
            throw new Error(`Failed to fetch, status code: ${res.status}`);
          }
          const data = await res.json();
          const tourDetail = convertTourDetailImages(data);
          setTourDetail(tourDetail);
        } catch (error) {
          console.error('Error fetching tour detail:', error);
        }
      }
    };

    fetchTourDetail();
  }, [locale, selectedTourIdForDetail]);

  const handleClose = () => {
    setSelectedTourIdForDetail(null);
    setTourDetail(null);
  };

  const handleChoose = () => {
    setSelectedTourIdForBooking(tourDetail?.id!);
    setSelectedTourIdForDetail(null);
    onSelect();
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handlePreviousImage = () => {
    if (tourDetail !== null) {
      setSelectedImageIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : tourDetail.images.length - 1,
      );
    }
  };

  const handleNextImage = () => {
    if (tourDetail !== null) {
      setSelectedImageIndex(prevIndex =>
        prevIndex < tourDetail.images.length - 1 ? prevIndex + 1 : 0,
      );
    }
  };

  return (
    <>
      <Modal open={selectedTourIdForDetail !== null} onClose={handleClose}>
        <Container>
          <Wrapper>
            <Header>
              <Typography variant="h5" fontWeight="fontWeightBold">
                {t('tourDetails')}
              </Typography>
              <Button sx={{ p: 0, height: 'auto' }} onClick={handleClose}>
                <Image
                  src="/assets/icons/close.svg"
                  alt="close"
                  height={13.5}
                  width={13.5}
                />
              </Button>
            </Header>
            {tourDetail ? (
              <>
                <Body>
                  <Box>
                    <ImageList
                      sx={{ width: '100%', height: 194 }}
                      variant="quilted"
                      cols={4}
                      rowHeight={95}
                    >
                      {tourDetail.images.map((image, index) => (
                        <ImageListItem
                          key={index}
                          cols={itemData[index]?.cols || 1}
                          rows={itemData[index]?.rows || 1}
                          onClick={() => handleImageClick(index)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <img
                            src={image}
                            alt={index.toString()}
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    paddingBottom={2}
                  >
                    <Content
                      dangerouslySetInnerHTML={{
                        __html: tourDetail.tourContents[0].content,
                      }}
                    />
                    <TermsLink onClick={() => setOpenTermsModal(true)}>
                      {t('common:terms')}
                    </TermsLink>
                  </Box>
                </Body>
              </>
            ) : (
              <PreLoading />
            )}
          </Wrapper>
          {tourDetail && (
            <Footer>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body4">
                  {t('common:estimatedTotal')}
                </Typography>
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color={theme => theme.palette.hightlight.main}
                >
                  {`${t('common:from')} ${formatPrice(
                    tourDetail.minPrice,
                  )} / ${t('common:car')}`}
                </Typography>
              </Box>
              <Button variant="contained" onClick={handleChoose}>
                {t('common:select')}
              </Button>
            </Footer>
          )}
        </Container>
      </Modal>

      {/* Image Modal */}
      <ImageModal
        images={tourDetail?.images ?? []}
        open={openImageModal}
        onClose={handleCloseImageModal}
        onNextImage={handleNextImage}
        onPreviousImage={handlePreviousImage}
        onSelectImage={setSelectedImageIndex}
        selectedImageIndex={selectedImageIndex}
      />
      <TermsModal
        isOpen={openTermsModal}
        onClose={() => setOpenTermsModal(false)}
        locale={locale}
      />
      {/* <Modal open={openImageModal} onClose={handleCloseImageModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            padding: 2,
          }}
        >
          <Box sx={{ position: 'relative' }}>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected Tour Image"
                // layout="intrinsic"
                // width={800}
                // height={600}
                style={{ maxWidth: '90vw', maxHeight: '80vh' }}
              />
            )}
            <IconButton
              onClick={handlePreviousImage}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
            >
              <ArrowBack
                sx={{
                  fill: 'white',
                  fontSize: '32px',
                  filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
            >
              <ArrowForward
                sx={{
                  fill: 'white',
                  fontSize: '32px',
                  filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleCloseImageModal}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 10,
              }}
            >
              <Close
                sx={{
                  fill: 'white',
                  fontSize: '32px',
                  filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
                }}
              />
            </IconButton>
          </Box>
          <ImageList
            sx={{ width: '100%', height: 120, marginTop: 2 }}
            variant="standard"
            cols={4}
            rowHeight={120}
          >
            {tourDetail !== null &&
              tourDetail.images.map((image, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => {
                    setSelectedImage(image);
                    setSelectedImageIndex(index);
                  }}
                  sx={{
                    cursor: 'pointer',
                    border:
                      index === selectedImageIndex ? '2px solid white' : 'none',
                  }}
                >
                  <img
                    src={image}
                    alt={`Thumbnail ${index}`}
                    // fill
                    style={{ objectFit: 'cover', width:'100%', height:'100%' }}
                  />
                </ImageListItem>
              ))}
          </ImageList>
        </Box>
      </Modal> */}
    </>
  );
}
