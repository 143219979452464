import { Box, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const BookingContainer = styled(Box)(({ theme }: any) => ({}));

const bookingBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  '& > h3': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
  },
};

export const BookingForm = styled('form')(({ theme }: any) => ({
  paddingTop: '32px',
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  justifyContent: 'space-between',

  '& > h2': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    marginBottom: '24px',
  },

  [theme.breakpoints.down('md')]: {
    marginBottom: '24px',

    '& > h2': {
      textAlign: 'center',
    },
  },

  '&.loading': {
    padding: 0,
    justifyContent: 'center',
    div: {
      position: 'relative',
    },
  },
}));

export const BookingTripInformation = styled(Box)(
  ({ theme }) =>
    ({
      ...bookingBox,

      '.wrapper-input-button': {
        display: 'flex',
        gap: '12px',

        '& > button': {
          marginTop: '4px',
          height: '40px',
          width: '40px',
          backgroundColor: '#F9FBFE',

          '& > svg': {
            color: theme.palette.black[20],

            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    } as any),
);

export const BookingNumberOfPeople = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',

  '.MuiTextField-root': {
    // width: 'calc(50% - 4px)',
  },

  [theme.breakpoints.down('md')]: {
    '.MuiTextField-root': {
      // width: 'auto',
    },
  },

  [theme.breakpoints.down(475)]: {
    flexDirection: 'column',

    '.MuiTextField-root': {
      // width: '100%',
    },
  },
}));

export const BookingPersonalInformation = styled(Box)(
  ({ theme }) =>
    ({
      ...bookingBox,

      '.wrapper-input-button': {
        display: 'flex',
        gap: '12px',

        '& > button': {
          marginTop: 0,
          height: '48px',
          width: '48px',
          border: '1px solid #c4c4c4',
          borderRadius: '8px',
          '& > svg': {
            color: theme.palette.black[20],

            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    } as any),
);

export const BookingDateTime = styled(Box)(
  ({ theme }) =>
    ({
      ...bookingBox,

      '.date-time': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',

        [theme.breakpoints.down('md')]: {
          '.MuiTextField-root': {
            width: 'auto',
          },
        },

        [theme.breakpoints.down(475)]: {
          flexDirection: 'column',
          gap: 0,

          '.MuiTextField-root': {
            width: '100%',
          },
        },
      },

      '.MuiInputBase-multiline': {
        borderRadius: '8px',
      },

      '.error-message': {
        textAlign: 'center',
        fontSize: '0.75rem',
        fontWeight: '400',
        color: '#C22625',
        padding: 0,
        margin: 0,
      },
    } as any),
);

export const StyledSelectDialCode = styled(Select)(({ theme }) => ({
  width: '110px',
  height: '48px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  outline: 'none',
  borderRadius: '8px',

  '.MuiSvgIcon-root': {
    right: '3px',
  },

  '.MuiSelect-select': {
    minHeight: 'auto !important',
  },
}));

export const CardFooter = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: 74,
  padding: '12px 24px',
}));

export const PersonalInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    gap: '10px',
  },
}));
