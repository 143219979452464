import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.white.main,
  borderRadius: '10px',
  padding: '32px 0 0',
  overflow: 'hidden',
  maxWidth: '800px',
  minWidth: '700px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '700px',
    minWidth: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '500px',
    minWidth: '100vw',
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '0 16px 0',
  overflow: 'auto',
  height: '85vh',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: 16,
}));

export const Content = styled(Box)(({ theme }) => ({
  maxHeight: "80vh",
  overflow: "auto"
}));
