import { apiClient } from './apiService';

export const paymentService = {
  getVNPayUrl(data: { id?: string; paymentType: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/confirm_payment`,
      data,
    });
  },

  getVNPayUrlMobile(data: { id?: string; paymentType: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/confirm_payment_mobile`,
      data,
    });
  },

  paymentSuccess(data: { id?: string }) {
    return apiClient.request({
      method: 'POST',
      url: `mobileCustomer/success_payment`,
      data,
    });
  },

  paymentSuccessMobile(data: { id?: string }) {
    return apiClient.request({
      method: 'POST',
      url: `mobileCustomer/success_payment_mobile`,
      data,
    });
  },

  getMBBankUrl(id: string) {
    return apiClient.request({
      method: 'POST',
      url: `/orders/${id}/mb_payment_link`
    });
  },
};
