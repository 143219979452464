import Terms from '@/components/Terms';
import { Button, Modal } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { Container, Content, Header, Wrapper } from './style';

type Props = {
  onClose: () => void;
  isOpen?: boolean;
  locale?: string;
};

export default function TermsModal({ onClose, isOpen = false, locale }: Props) {
  const { t } = useTranslation('common');

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Container>
        <Wrapper>
          <Header>
            <Button sx={{ p: 0, height: 'auto' }} onClick={onClose}>
              <Image
                src="/assets/icons/close.svg"
                alt="close"
                height={13.5}
                width={13.5}
              />
            </Button>
          </Header>
          <Content>
            <Terms locale={locale} />
          </Content>
        </Wrapper>
      </Container>
    </Modal>
  );
}
