/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SmallKakaotalkIcon, SmallWhatsappIcon, ZaloIcon } from '../SvgIcons';
import { Container } from './style';

type Props = {
  isOpen: boolean;
  yesAction: () => void;
  maxLimit: number;
};

export default function PassengersLimitModal({
  isOpen,
  yesAction,
  maxLimit,
}: Props) {
  const { t } = useTranslation('common');

  return (
    <Modal open={isOpen} onClose={yesAction}>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography variant="h2" fontWeight={600} textAlign="center">
            {t('exceededNumberOfGuests')}
          </Typography>
          <Typography variant="body2" fontWeight={500} textAlign="center">
            {t('maxGuestsPerTour', { value: maxLimit })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body2" textAlign="center" fontWeight={400}>
            {t('pleaseContactHotline')}
            <br />
            <Typography fontWeight={700} component="span">
              1900 996690
            </Typography>
            <br />
            {t('orEmail')}
            <Typography fontWeight={700} component="span">
              xinchao@hoiango.com{' '}
            </Typography>
            {t('forSupport')}
          </Typography>
        </Box>
        <Box display="flex" gap={4}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <ZaloIcon />
            <SmallWhatsappIcon />{' '}
            <Typography variant="body4" fontWeight={400}>
              (+84)905633616
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <SmallKakaotalkIcon />{' '}
            <Typography variant="body4" fontWeight={400}>
              @hoiango
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" gap={2}>
          <Button variant="contained" onClick={yesAction}>
            <Typography fontWeight={600} color={t => t.palette.white.main}>
              {t('common:agree')}
            </Typography>
          </Button>
        </Box>
      </Container>
    </Modal>
  );
}
