/* eslint-disable @next/next/no-img-element */
import ImageModal from '@/components/ImageModal';
import { useTourBookingContext } from '@/containers/TourBookingByCustomer/providers/useTourBookingContext';
import { TourDetail } from '@/types/tourBooking';
import { formatPrice } from '@/utils/formatPrice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChooseButton,
  DetailButton,
  ItemContent,
  ItemLabel,
  ItemPrice,
  TourListItemWrap,
} from './style';

type Props = {
  tour: TourDetail;
  isDetailedMode?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  padding?: string;
};

export default function TourListItem({
  tour,
  isDetailedMode,
  onClick,
  isSelected,
  padding,
}: Props) {
  const { t } = useTranslation('common');
  const { setSelectedTourIdForDetail, setSelectedTourIdForBooking } =
    useTourBookingContext();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handlePreviousImage = () => {
    if (tour !== null) {
      setSelectedImageIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : tour.images.length - 1,
      );
    }
  };

  const handleNextImage = () => {
    if (tour !== null) {
      setSelectedImageIndex(prevIndex =>
        prevIndex < tour.images.length - 1 ? prevIndex + 1 : 0,
      );
    }
  };

  return (
    <TourListItemWrap
      className={isSelected ? 'active' : ''}
      padding={padding ? padding : '12px 12px 12px 24px'}
    >
      <div style={{ width: '104px' }}>
        <img
          src={tour.images[0]}
          alt="images"
          style={{
            objectFit: 'cover',
            width: '104px',
            height: '104px',
            borderRadius: '6px',
            cursor: 'pointer',
          }}
          onClick={() => {
            isDetailedMode
              ? handleImageClick(0)
              : setSelectedTourIdForBooking(tour.id);
          }}
        />
      </div>

      <ItemContent>
        <ItemLabel onClick={() => setSelectedTourIdForDetail(tour.id)}>
          {tour.tourContents?.[0].title}
        </ItemLabel>
        <ItemPrice>{`${t('common:from')} ${formatPrice(tour.minPrice)} / ${t(
          'common:car',
        )}`}</ItemPrice>
        {isDetailedMode ? (
          <DetailButton
            variant="contained"
            onClick={() => {
              setSelectedTourIdForDetail(tour.id);
              onClick?.();
            }}
          >
            {t('common:tourDetails')}
          </DetailButton>
        ) : (
          <ChooseButton
            variant="contained"
            onClick={() => {
              setSelectedTourIdForBooking(tour.id);
              onClick?.();
            }}
          >
            {t('common:select')}
          </ChooseButton>
        )}
      </ItemContent>
      <ImageModal
        images={tour.images}
        open={openImageModal}
        onClose={handleCloseImageModal}
        onNextImage={handleNextImage}
        onPreviousImage={handlePreviousImage}
        onSelectImage={setSelectedImageIndex}
        selectedImageIndex={selectedImageIndex}
      />
    </TourListItemWrap>
  );
}
