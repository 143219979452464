export const formatMillisecondsToHours = (milliseconds: string | number) => {
  var numberValue = 0;

  if (typeof milliseconds === 'number') {
    numberValue = milliseconds;
  } else {
    numberValue = parseInt(milliseconds, 10);
  }

  const date = new Date(numberValue);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const convertMinutesToDaysMinutes = (
  minutes: number,
): { days: number; minutes: number } => {
  const oneDayMinutes = 1440;

  const days = Math.floor(minutes / oneDayMinutes);
  const mins = Math.floor(minutes % oneDayMinutes);
  return { days: days, minutes: mins };
};
