import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '32px 0 24px 0',
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,
  fontSize: '14px',
  lineHeight: '22px',

  '& > h1': {
    marginBottom: '20px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    paddingLeft: '24px',
  },
}));

export const TourListWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxHeight: '670px',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '100%',
  },
}));
