import React, { memo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CheckBoxIcon from 'public/assets/icons/checkbox.svg';

function CheckboxTourBooking(
  props: CheckboxProps & { label: React.ReactNode },
) {
  const { label, ...otherProps } = props;

  return (
    <Box border="1px solid #c4c4c4" padding="0 8px" borderRadius="8px">
      <FormControlLabel
        label={label}
        sx={{
          width: '100%',
          '& .MuiFormControlLabel-label': {
            width: '100%',
          },
        }}
        control={
          <Checkbox
            {...otherProps}
            icon={
              <Box padding="4px" display="flex" alignItems="center">
                <CheckBoxIcon />
              </Box>
            }
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 32 },
            }}
          />
        }
      />
    </Box>
  );
}

export default memo(CheckboxTourBooking);
