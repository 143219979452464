import { ICreateTourBooking } from '@/types/interface';
import { apiClient } from './apiService';
import { handleLocalStorage } from '@/utils/handleLocalStorage';

const getToken = () => {
  return handleLocalStorage.get('accessToken');
};

export const tourBookingService = {
  createBooking(data: ICreateTourBooking) {
    return apiClient.request({
      method: 'POST',
      url: '/booking_tour/create_tour_order',
      data,
    });
  },

  getTourDetail(locale: string, code: string) {
    return apiClient.request({
      method: 'GET',
      url: `/booking_tour/${locale}/order/${code}/tour_detail`,
    });
  },

  createBookingByPartner(data: ICreateTourBooking) {
    return apiClient.request({
      method: 'POST',
      url: '/booking_tour/create_tour_order',
      data,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  },
};
