import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from '@mui/material';
import React, { forwardRef, memo } from 'react';

export type Option = {
  label: string;
  value: any;
};

type Props = {
  value?: any;
  options?: Option[];
  label: string | React.ReactNode;
  error?: boolean;
  helperText?: string | null;
  register?: any;
  control?: any;
};

const SelectFieldCustom = forwardRef<any, SelectProps & Props>((props, ref) => {
  const {
    value,
    onChange,
    onBlur,
    options,
    label,
    error,
    helperText,
    register,
    ...otherProps
  } = props;

  return (
    <StyledFormControl fullWidth variant="filled" error={error}>
      <InputLabel>{label}</InputLabel>

      <Select
        ref={ref}
        label={label}
        onChange={(e, child) => {
          onChange?.(e, child);
          register?.onChange(e.target.value);
        }}
        size="small"
        {...otherProps}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>

      {helperText && helperText !== null && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
});

SelectFieldCustom.displayName = 'SelectFieldCustom';

export default memo(SelectFieldCustom);

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minHeight: 48,
  label: {
    lineHeight: 'unset',
  },
  '& .MuiFilledInput-root': {
    height: 48,
    fontWeight: 500,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.white.main,
    border: '1px solid',
    borderColor: '#c4c4c4',
    boxShadow: 'unset',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      borderBottom: 'unset',
    },
    '&:after': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}`,
      backgroundColor: theme.palette.white.main,

      '&:before': {
        borderBottom: 'unset!important',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
}));
