/* eslint-disable @next/next/no-img-element */
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
} from '@mui/material';
import React from 'react';

interface ImageModalProps {
  open: boolean;
  images: string[];
  selectedImageIndex: number;
  onClose: () => void;
  onPreviousImage: () => void;
  onNextImage: () => void;
  onSelectImage: (index: number) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  open,
  images,
  selectedImageIndex,
  onClose,
  onPreviousImage,
  onNextImage,
  onSelectImage,
}) => {
  const isSingleImage = images.length === 1;

  const handleClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        onClick={handleClose}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          padding: 2,
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {images[selectedImageIndex] && (
            <img
              src={images[selectedImageIndex]}
              alt="Selected Tour Image"
              style={{ maxWidth: '90vw', maxHeight: '80vh' }}
            />
          )}
          {!isSingleImage && (
            <>
              <IconButton
                onClick={onPreviousImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                }}
              >
                <ArrowBack
                  sx={{
                    fill: 'white',
                    fontSize: '32px',
                    filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
                  }}
                />
              </IconButton>
              <IconButton
                onClick={onNextImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                }}
              >
                <ArrowForward
                  sx={{
                    fill: 'white',
                    fontSize: '32px',
                    filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
                  }}
                />
              </IconButton>
            </>
          )}
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <Close
              sx={{
                fill: 'white',
                fontSize: '32px',
                filter: 'drop-shadow(0 0 0.25rem rgb(0 0 0))',
              }}
            />
          </IconButton>
        </Box>
        {!isSingleImage && (
          <ImageList
            sx={{ width: '100%', height: 120, marginTop: 2 }}
            variant="standard"
            cols={4}
            rowHeight={120}
          >
            {images.map((image, index) => (
              <ImageListItem
                key={index}
                onClick={() => onSelectImage(index)}
                sx={{
                  cursor: 'pointer',
                  border:
                    index === selectedImageIndex ? '2px solid white' : 'none',
                }}
              >
                <img
                  src={image}
                  alt={`Thumbnail ${index}`}
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
    </Modal>
  );
};

export default ImageModal;
