import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  height: 200,
  backgroundColor: theme.palette.white.main,
  borderRadius: '10px',
  padding: '40px 16px 16px',
  overflow: 'hidden',
}));

