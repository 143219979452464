import { useTourBookingContext } from '@/containers/TourBookingByCustomer/providers/useTourBookingContext';
import { Tour } from '@/types/tourBooking';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TourListItem from '../TourListItem';
import { Container, TourListWrap } from './style';

type Props = {
  onSelectItem: () => void;
  tours: Tour[];
};

export default function TourList({ tours, onSelectItem }: Props) {
  const { t } = useTranslation('common');
  const { selectedTourIdForBooking } = useTourBookingContext();

  return (
    <Container>
      <Typography component="h1">{t('tourList')}</Typography>
      <TourListWrap>
        {tours.map((tour, index) => (
          <TourListItem
            key={index}
            isSelected={tour.id === selectedTourIdForBooking}
            tour={tour}
            onClick={onSelectItem}
          />
        ))}
      </TourListWrap>
    </Container>
  );
}
