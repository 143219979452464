export function formatPrice(value: number): string {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(value)
    .replace('₫', 'đ');
}

export function formatPriceWithNoUnit(value: number) {
  return value.toLocaleString('en-US').replace(/,/g, '.');
}
