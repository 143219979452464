import React, { forwardRef, memo } from 'react';
import {
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';

const TextFieldCustom = forwardRef<
  any,
  TextFieldProps & {
    register?: any;
  }
>((props, ref) => {
  const { register, onChange, onBlur, ...otherProps } = props;

  return (
    <StyledTextField
      ref={ref}
      {...register}
      {...otherProps}
      onChange={(
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      ) => {
        onChange && onChange(e);
        register?.onChange(e);
      }}
      onBlur={e => {
        onBlur && onBlur(e);
        register?.onBlur(e);
      }}
    />
  );
});

TextFieldCustom.displayName = 'TextFieldCustom';

export default memo(TextFieldCustom);

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 8,
  },
  '& .MuiFilledInput-root': {
    fontWeight: 500,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.white.main,
    border: '1px solid',
    borderColor: '#c4c4c4',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}`,
      backgroundColor: theme.palette.white.main,
      '&:before': {
        borderBottom: 'unset!important',
      },
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
}));
