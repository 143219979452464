import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const TourListItemWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 10,
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const ItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'space-between',
}));

export const ItemLabel = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.5px',
  color: theme.palette.black.main,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export const ItemPrice = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '17.07px',
  color: theme.palette.hightlight.main,
}));

export const ChooseButton = styled(Button)(({ theme }) => ({
  padding: 8,
  borderRadius: '100px',
  boxShadow: 'unset',
  width: 64,
  height: 32,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17.07px',
  color: theme.palette.white.main,
}));

export const DetailButton = styled(Button)(({ theme }) => ({
  padding: 16,
  borderRadius: '100px',
  boxShadow: 'unset',
  width: 'max-content',
  height: 32,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17.07px',
  color: theme.palette.white.main,
  backgroundColor: '#000',
}));
