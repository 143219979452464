import React, { memo } from 'react';
import { ArrowLeftIcon } from '@/components/SvgIcons';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function BackButton({
  title,
  onClick,
}: {
  title?: string;
  onClick?: () => void;
}) {
  const { t } = useTranslation('common');
  return (
    <Button sx={{ p: 0 }} onClick={onClick}>
      <Box display="flex" alignItems="center" gap={1}>
        <ArrowLeftIcon fontSize="inherit" />
        <Typography
          variant="body3"
          fontWeight={600}
          color={t => t.palette.hightlight.main}
        >
          {title ?? t('back')}
        </Typography>
      </Box>
    </Button>
  );
}

export default memo(BackButton);
