export interface TourContent {
  id: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  language: string;
  title: string;
  content: string;
}

export interface TourPriceOption {
  id: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  tourId: string;
  duration: number;
  minPrice: number;
  pricePassenger: number;
}

export enum TourType {
  ADVENTURES = 'adventures',
  EXPERIENCE = 'experience',
}

export interface Tour {
  id: string;
  images: string[];
  minPrice: number;
  tourType: TourType;
  reserveTime?: number;
  passengerLimit: number;
  startActiveTime?: number;
  endActiveTime?: number;
  tourContents: TourContent[];
  tourPriceOptions: TourPriceOption[];
}

export interface TourDetail {
  id: string;
  images: string[];
  minPrice: number;
  tourContents: TourContent[];
}

export interface TourAdditionalService {
  id: string;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  language: string;
  name: string;
  price: number;
  slot: number;
}
